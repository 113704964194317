import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";

const initialState = {
  data: {
    UserId: 0,
    userName: null,
    EmailId: null,
    Role: null,
    lastLoggedIn: null,
    applicationVersion: null,
    CompanyName: null,
    timezone: moment.tz.guess(),
    userImage: null,
    langauage: 1,
    lsttechnologyID: [],
  },
  isFirstTimeLoggedIn: false,
  isIXPermission: false,
};

export const UserInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.data = action.payload;
      localStorage.setItem("UserInfo", JSON.stringify(action.payload));
    },
    updateIXPermission: (state, action) => {
      state.isIXPermission = action.payload;
    },
    updateAppVersion: (state, action) => {
      state.data.applicationVersion = action.payload;
    },
    updateUserInfoData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updateIsFirstTimeLoggedIn: (state, action) => {
      state.isFirstTimeLoggedIn = action.payload;
    },
  },
});

export const { updateUserInfo, updateIXPermission, updateAppVersion, updateUserInfoData, updateIsFirstTimeLoggedIn } =
  UserInfoSlice.actions;
export default UserInfoSlice.reducer;
