import { useDispatch, useSelector } from "react-redux";

import API_URLS from "@constants/api.urls";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import DupontLogger from "@utils/DupontLogger";

import { useToastNotification } from "@components/WPToastNotification";

import { updateIsFirstTimeLoggedIn, updateUserInfoData } from "@common/UserInfoSlice";

import { updateLoader, updateProjectsList, updateSubTechnology } from "@features/home/CardListSlice";
import { HomeSideNavIds, HomeSidNavUrlMapping } from "@features/home/HomeSideNav";
import { updateFolderList, updateFolderLoader } from "@features/home/ViewAllFolderSlice";

import { getFolderList, getRequest, moveFolder, putRequest } from "@api/index";
import { getUserInformation } from "@api/userApi/userApi";

const useHome = () => {
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;

  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const activeMenu = useSelector(state => state.leftpanel.activeMenu);
  const activeFolderId = useSelector(state => state.Folderview.activeFolder.id);

  const Logger = DupontLogger("useHome hook");

  const dispatch = useDispatch();

  const getFolderListData = async () => {
    try {
      dispatch(updateFolderLoader(true));
      const resp = await getFolderList({ userID });
      const folderRespData = resp.data;
      dispatch(updateFolderList(folderRespData));
      dispatch(updateFolderLoader(false));
    } catch (error) {
      Logger.error("getFolderList api error", error);
    }
  };

  const renameProject = async ({ projectID, projectName }) => {
    try {
      await putRequest(API_URLS.renameProject, { projectID, projectName, userID });
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_RENAMED);
      getHomeListData();
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.PROJECT_RENAME_FAILED);
      Logger.error("RenameProject api error", error);
    }
  };

  const restoreFolder = async ({ folderId }) => {
    try {
      await putRequest(API_URLS.restoreFolder, { folderId, userID });
      showSuccessNotification(APP_TOAST_NOTIFICATION.FOLDER_RESTORED);
      getHomeListData();
      getFolderListData();
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.FOLDER_RESTORE_FAILED);
      Logger.error("restoreFolder api error", error);
    }
  };

  const restoreProject = async ({ projectID }) => {
    try {
      await putRequest(API_URLS.restoreProject, { projectID, userID });
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_RESTORED);
      getHomeListData();
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.PROJECT_RESTORE_FAILED);
      Logger.error("restoreProject api error", error);
    }
  };

  const getSubTechnology = async () => {
    try {
      const { data } = await getRequest(API_URLS.subTechnology);
      dispatch(updateSubTechnology(data));
    } catch (error) {
      Logger.error("subTechnology api error", error);
    }
  };

  const toggleFavoriteProject = async ({ projectID, favorite }) => {
    try {
      await putRequest(API_URLS.favoriteProject, { projectID, favorite, userID });

      const successMessage = favorite ? APP_TOAST_NOTIFICATION.FAVORITE_ADDED : APP_TOAST_NOTIFICATION.FAVORITE_REMOVED;
      showSuccessNotification(successMessage);
      getHomeListData();
    } catch (error) {
      const errorMessage = favorite
        ? APP_TOAST_NOTIFICATION.FAVORITE_ADD_FAILED
        : APP_TOAST_NOTIFICATION.FAVORITE_REMOVE_FAILED;
      showErrorNotification(errorMessage);
      Logger.error("FavoriteProject api error", error);
    }
  };

  // calls the api associated to the side nav
  const getHomeListData = async (sideNavMenuID, isToShowLoader = true) => {
    if (isToShowLoader) dispatch(updateLoader(true));
    const menuID = sideNavMenuID || activeMenu;
    const url = HomeSidNavUrlMapping[menuID];
    if (!url) {
      return;
    }
    const params = { userID };
    if (activeFolderId) {
      params.folderID = activeFolderId;
    }
    try {
      const { data: respData } = await getRequest(url, params);
      let data = [];
      if (activeMenu === HomeSideNavIds.deleted) {
        data = [...respData.lstprojectInfoHomePageVM, ...respData.lstfolderDeleteVM];
      } else if (activeMenu === HomeSideNavIds.importedProjects) {
        data = respData.filter(project => project.isImported);
      } else {
        data = respData;
      }
      if (isToShowLoader) dispatch(updateLoader(false));
      dispatch(updateProjectsList(data));
    } catch (error) {
      Logger.error("getHomeListData api error", error);
    }
  };

  const removeFolder = async (projectId, folderId) => {
    const payload = {
      projectID: projectId,
      folderID: folderId,
      addProjectToFolder: false,
    };

    try {
      await moveFolder(payload);
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_REMOVED_FROM_FOLDER);
      getHomeListData();
    } catch (e) {
      showErrorNotification(APP_TOAST_NOTIFICATION.PROJECT_REMOVE_FROM_FOLDER_FAILED);
    }
  };

  const getUserInfo = async () => {
    const response = await getUserInformation();
    const { firstName: userName, timeZone, userImage, lsttechnologyID } = response.data;
    dispatch(
      updateUserInfoData({
        userName,
        timeZone,
        userImage,
        lsttechnologyID,
      }),
    );
    dispatch(updateIsFirstTimeLoggedIn(false));

    return response;
  };

  return {
    getFolderListData,
    getSubTechnology,
    toggleFavoriteProject,
    getHomeListData,
    renameProject,
    restoreFolder,
    restoreProject,
    removeFolder,
    getUserInfo,
  };
};

export default useHome;
